(function(_exports, $) {
/** ====================================================
 *
 * main.js
 *
 *
 *
 *
 * ==================================================== */

    var base_width = 1000;

    /**
     *   ページ内リンク
     * ==================================== */
    var setInPageLink = function () {
        var target = $("a")
            ;

        target.click(function (e) {
            var href = $(this).attr('href')
                ;

            if (href.match(/^#/) && $(href).length > 0) {
                e.preventDefault();
                console.log(href);
                $('html, body').animate({scrollTop: $(href).offset().top}, 'fast');
            }
        });
    };


    /**
     *   SP用 モーダル開閉メニュー
     * ==================================== */
    var setToggleMenu = function() {
        var t_btn = $(".toggle-nav"),
            t_links = $(".sp-navigation li a"),
            body = $("body")
        ;

        t_btn.on("click", function(){
            var target = $(this)
            ;

            target.toggleClass("is-open");

            body.toggleClass("sp-menu-open");
        });

        t_links.on("click", function(){
            var target = $(this)
                ;

            t_btn.removeClass("is-open");
            body.removeClass("sp-menu-open");
        })
    };


    /**
     *  スクロール時に該当箇所でモーションを起こす
     * ==================================== */
    var setScrollMotion = function() {
        $('.motion').css('visibility','hidden');
        $(window).scroll(function(){
            var windowHeight = $(window).height(),
                topWindow = $(window).scrollTop();
            $('.motion').each(function(){
                var targetPosition = $(this).offset().top;
                if(topWindow > targetPosition - windowHeight + 100){
                    $(this).addClass("fadeInDown");
                }
            });
        });
    };


    /**
     *  トップへ戻るボタン 画面スクロールで表示・非表示
     * ==================================== */
    var setAppearToTop = function() {
        var target = $(".totop"),
            window_w = $(window).width(),
            top = 500,
            fade_time = 250
            ;

        if ( window_w >= base_width ) {
            target.hide();

            $(window).scroll(function () {
                var stop = $(window).scrollTop();
                if (stop > top) {
                    target.fadeIn(fade_time);
                } else {
                    target.fadeOut(fade_time);
                }
            });
        }
    };

    /**
     *  サイド電話・メールボタンの表示・非表示
     * ==================================== */
    var setButtonContact = function() {
        var target = $(".button-contact"),
            area_contact = $(".section-contact"),
            limit_height = area_contact.offset().top,
            window_h = $(window).height(),
            scroll_h,
            fade_time = 250
            ;

        $(window).scroll(function () {
            scroll_h = $(window).scrollTop();

            if (scroll_h > ( limit_height - window_h ) ) {
                target.fadeOut(fade_time);
            } else {
                target.fadeIn(fade_time);
            }
        });

    };

    $(document).ready(function(){
        setInPageLink();
        setToggleMenu();
        setScrollMotion();
        setAppearToTop();
        setButtonContact();
    });


})(typeof window !== "undefined" ? window : this, window.jQuery );